import {styled} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {routeSelector, routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {AccessManagementNavBar} from './access-management';
import {HomeNavBar} from './home';
import {UserGroupManagementNavBar} from './user-group-management';
import {PlanAndMeetNavBar} from './plan-and-meet';
import {DcrUnlockNavBar} from './dcr-unlock-request';
import {PositionManagementNavbar} from './position-management';
import {ApprovalWorkflowNavbar} from './approval-workflow';
import {ActivityMappingNavBar} from './activity-mapping';
import {GspScheduleNavbar} from './gsp-schedule';
import { DataCorrectionNavBar } from './data-correction';

const styles = {
  toolbar: {
    backgroundColor: '#dde8ec',
    color: '#1e1c3b',
    padding: '20px',
    borderRadius: '20px',
  },
};

const AppBar: any = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})((props: any) => ({
  transition: props.theme.transitions.create(['margin', 'width'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  ...(!props.open && {
    width: 'calc(100% - 240px)',
    marginRight: 240,
  }),
  ...(props.open && {
    width: 'calc(100% - 480px)',
    borderRadius: 20,
    marginRight: 240,
    transition: props.theme.transitions.create(['margin', 'width'], {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const NavBar = ({authorized}: {authorized?: boolean}) => {
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(routeSelector.getSideBarOpen(), shallowEqual);
  const navbarComponentNameSelector = useSelector(
    routeSelector.getNavbarComponentName(),
    shallowEqual,
  );
  const handleDrawerOpen = () => {
    dispatch(routeStateActions.setSideBarOpen(true));
  };

  return (
    <AppBar position="fixed" open={sideBarOpen}>
      <Toolbar sx={styles.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{mr: 2, ...(sideBarOpen && {display: 'none'})}}>
          <MenuIcon />
        </IconButton>
        {authorized && (
          <>
            {navbarComponentNameSelector ===
              navbarComponentName.accessManagement && (
              <AccessManagementNavBar />
            )}
            {navbarComponentNameSelector ===
              navbarComponentName.accessGroupMaster && (
              <UserGroupManagementNavBar />
            )}
            {navbarComponentNameSelector ===
              navbarComponentName.planAndMeet && <PlanAndMeetNavBar />}
            {navbarComponentNameSelector ===
              navbarComponentName.dcrUnlockRequest && <DcrUnlockNavBar />}
            {navbarComponentNameSelector === navbarComponentName.home && (
              <HomeNavBar />
            )}
            {navbarComponentNameSelector ===
              navbarComponentName.positionManagement && (
              <PositionManagementNavbar />
            )}
            {navbarComponentNameSelector ===
              navbarComponentName.approvalWorkflow && (
              <ApprovalWorkflowNavbar />
            )}
            {navbarComponentNameSelector ===
              navbarComponentName.activityMapping && <ActivityMappingNavBar />}
            {navbarComponentNameSelector ===
              navbarComponentName.gspSchedule && <GspScheduleNavbar />}
            {navbarComponentNameSelector === navbarComponentName.dataCorrection && 
              <DataCorrectionNavBar/>}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
