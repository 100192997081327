import { combineReducers } from 'redux';
import { authTokenReducer } from '@app/screens/generic/Login/redux';
import { routeStateReducer } from '@app/router/redux';
import { filterStateReducer } from '@app/components/right-sidebar/redux';
import { appSliceReducer } from './appSlice';
import { userManagementStateReducer } from '@app/screens/access-management/pages/user-management/redux';
import { accessGroupMasterStateReducer } from '@app/screens/access-management/pages/access-group-master/redux';
import { planAndMeetStatusStateReducer } from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import { dcrUnlockRequestStateReducer } from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/slice';
import { positionManagementStateReducer } from '@app/screens/module-specific-utilities/pages/position-management/redux'
import { approvalWorkflowStateReducer } from '@app/screens/configuration-management/pages/approval-workflow/redux/slice';
import { activityMappingStateReducer } from '@app/screens/configuration-management/pages/activity-mapping/redux'
import { gspScheduleStateReducer } from '@app/screens/configuration-management/pages/gsp-schedule/redux';
import { dataCorrectionStateReducer } from '@app/screens/master-data-management/pages/data-correction/redux'

export const rootReducer = combineReducers({
  appState: appSliceReducer,
  authState: authTokenReducer,
  routeState: routeStateReducer,
  filterState: filterStateReducer,
  userManagement: userManagementStateReducer,
  accessGroupMaster: accessGroupMasterStateReducer,
  planAndMeetStatusMaster: planAndMeetStatusStateReducer,
  dcrUnlockRequestMaster: dcrUnlockRequestStateReducer,
  positionManagementMaster: positionManagementStateReducer,
  approvalWorkflowMaster: approvalWorkflowStateReducer,
  activityMappingMaster: activityMappingStateReducer,
  gspScheduleMaster: gspScheduleStateReducer,
  dataCorrectionMaster: dataCorrectionStateReducer,
});
export { } from './appSlice';
